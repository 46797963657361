exports.components = {
  "component---features-cloud-elephants-core-src-pages-documentation-index-js": () => import("./../../../../../features/cloud-elephants-core/src/pages/__documentation/index.js" /* webpackChunkName: "component---features-cloud-elephants-core-src-pages-documentation-index-js" */),
  "component---features-cloud-elephants-core-src-pages-documentation-styleguide-colors-js": () => import("./../../../../../features/cloud-elephants-core/src/pages/__documentation/styleguide/colors.js" /* webpackChunkName: "component---features-cloud-elephants-core-src-pages-documentation-styleguide-colors-js" */),
  "component---features-cloud-elephants-core-src-pages-documentation-styleguide-index-js": () => import("./../../../../../features/cloud-elephants-core/src/pages/__documentation/styleguide/index.js" /* webpackChunkName: "component---features-cloud-elephants-core-src-pages-documentation-styleguide-index-js" */),
  "component---features-cloud-elephants-core-src-pages-documentation-styleguide-lists-mdx": () => import("./../../../../../features/cloud-elephants-core/src/pages/__documentation/styleguide/lists.mdx" /* webpackChunkName: "component---features-cloud-elephants-core-src-pages-documentation-styleguide-lists-mdx" */),
  "component---features-cloud-elephants-core-src-pages-documentation-styleguide-typography-mdx": () => import("./../../../../../features/cloud-elephants-core/src/pages/__documentation/styleguide/typography.mdx" /* webpackChunkName: "component---features-cloud-elephants-core-src-pages-documentation-styleguide-typography-mdx" */),
  "component---features-cloud-elephants-core-src-pages-templates-index-js": () => import("./../../../../../features/cloud-elephants-core/src/pages/__templates/index.js" /* webpackChunkName: "component---features-cloud-elephants-core-src-pages-templates-index-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */)
}

