module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/captainhurst/Projects/untamed/Bethsaida/customers/shanearmstrongcreative","commonmark":false},
    },{
      plugin: require('../../../features/cloud-elephants-core/gatsby-browser.js'),
      options: {"plugins":[],"shouldShowDocs":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-BLVVES2H5M"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":[]}},
    }]
